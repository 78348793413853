<template>
  <div class="layout">
    <router-view />

  </div>
</template>

<script>

export default {
  name: "Layout",

};
</script>
